body{
    font-family: 'Montserrat', sans-serif;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

#modal{
    z-index: 1000;
}
.app-top{
    display: flex;
    width: 100%;
}
.app-main{
    display: flex;
    flex-direction: column;
    width: 80%;
}